import { FC, useCallback, useEffect, useState, useMemo } from "react";
import ReactCodeInput from "react-verification-code-input";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useAuthDispatchContext, useAuthStateContext } from "providers/Auth";

import TwoFactorAuthentication from ".";
import { instance } from "utils/api";

const PhoneNumberVerification: FC = () => {
  const { sendCode } = useAuthDispatchContext();
  const history = useHistory();
  const { user } = useAuthStateContext();
  const [status, setStatus] = useState("code_is_valid");
  const [resendStatus, setResendStatus] = useState("");
  const [errors, setErrors] = useState("");
  const [code, setCode] = useState("");
  const [resendCount, setResendCounts] = useState(3);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState<string | number>("00");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isGoogleAuth = searchParams.get("two-factor-auth") === "google";

  useEffect(() => {
    if (minutes === 0 && parseInt(seconds as string) === 0) return;
    const myInterval = setInterval(() => {
      const parsedSeconds = parseInt(seconds as string);

      if (parsedSeconds > 0) {
        if (parsedSeconds <= 1 && minutes === 0) {
          return setSeconds(0);
        }

        if (parsedSeconds <= 10) {
          setSeconds((prev) => `0${(prev as number) - 1}`);
        } else {
          setSeconds((prev) => (prev as number) - 1);
        }
      }

      if (parsedSeconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes((prev) => prev - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(myInterval);
  }, [minutes, seconds]);

  const onResendCode = useCallback(async () => {
    try {
      const res = await instance.get("admin/verification-code/resend");

      setResendCounts(res.data.canTry);
      setResendStatus("sent_code_again");
      setStatus("code_is_valid");
      setMinutes(3);
      setSeconds("00");
    } catch (e) {
      setResendStatus("sent_code_again");
      setStatus("code_expired");
    }
  }, []);

  const onSendCode = async () => {
    try {
      await sendCode({
        twoFaCode: code,
        hesTwoFaPhoneNumber: Boolean(user && user.hesTwoFaPhoneNumber),
        isGoogleAuth,
        hasGoogleTwoFa: Boolean(user && user.hasGoogleTwoFa),
      });

      user && user.hesTwoFaPhoneNumber
        ? history.push("/dashboard")
        : history.push("/code-verification-success/set");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      switch (e.response.data.message) {
        case "The 2FA code is incorrect. Try again":
          setErrors("The 2FA code is incorrect. Try again");
          break;

        default:
          break;
      }
    }
  };

  const complated = useMemo(() => {
    return code.length > 5 && status !== "code_has_timed_out";
  }, [code.length, status]);

  useEffect(() => {
    if (seconds === 0) {
      setStatus("code_has_timed_out");
      setResendStatus("didnt_get_code");
    }
  }, [seconds]);

  const TEXT = {
    title: isGoogleAuth
      ? "Verify Google Authenticator"
      : "Phone number verification",
    description: isGoogleAuth
      ? " Enter code from Google Authenticator to make sure everything works."
      : "Enter the verification code we sent to",
  };

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">{TEXT.title}</h6>
          <p className="tfa-card-header-description">{TEXT.description}</p>
          {isGoogleAuth ? null : (
            <>
              <p className="tfa-card-header-description">
                <span className="description-number">{user?.phoneNumber}</span>
              </p>
              <p className="tfa-card-header-description">
                {((status = "code_is_valid") => {
                  switch (status) {
                    case "code_is_valid":
                      return (
                        <span>
                          Code is valid for{" "}
                          {minutes > 0 ? `${minutes}:${seconds}` : seconds}{" "}
                          {minutes > 0 ? "minutes" : "seconds"}.
                        </span>
                      );
                    case "code_has_timed_out":
                      return (
                        <span className="description-error">
                          The code has timed out
                        </span>
                      );
                    case "code_expired":
                      return (
                        <span className="description-error">
                          Verification code expired and you have tried all
                          attempts
                        </span>
                      );
                    default:
                      return null;
                  }
                })(status)}
              </p>
            </>
          )}
        </div>
        <div className="tfa-card-body">
          <div className="tfa-card-row">
            <label className="tfa-card-row-title">
              Type your 6 digit security code
            </label>
            <ReactCodeInput
              // values={code.}
              onChange={setCode}
              className={`phone-number-verfication ${
                errors && "tfa-input-error"
              }`}
            />
            <p
              className={`tfa-card-row-error-messege ${
                errors ? "input-error-message" : ""
              }`}
            >
              {errors}
            </p>
          </div>
        </div>
        <div className="tfa-card-footer">
          {isGoogleAuth ? null : (
            <div className="tfa-card-footer-link">
              {((status = "try_again") => {
                switch (status) {
                  case "didnt_get_code":
                    return (
                      <p>
                        Didn’t get the code?{" "}
                        <Link to="/code-verification" onClick={onResendCode}>
                          Resend
                        </Link>{" "}
                        or{" "}
                        <a
                          href="https://help.swaps.app/en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Support
                        </a>
                      </p>
                    );
                  case "sent_code_again":
                    return (
                      <p>
                        We sent you the code again. You have {resendCount}{" "}
                        attempts left
                      </p>
                    );
                  case "contact_support":
                    return (
                      <p>
                        <a
                          href="https://help.swaps.app/en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Contact support
                        </a>
                      </p>
                    );
                  // case "try_again":
                  //   return (
                  //     <p className="text--dark">
                  //       Try again in {Math.floor(resend / 60)} minutes
                  //     </p>
                  //   );
                  default:
                    return null;
                }
              })(resendStatus)}
            </div>
          )}
          <div className="tfa-card-footer-btn">
            <button
              disabled={!complated}
              onClick={onSendCode}
              type="submit"
              className="btn btn-lg btn-primary w-100"
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default PhoneNumberVerification;
